import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function (n) { return (_pushScopeId("data-v-4663fd54"), n = n(), _popScopeId(), n); };
var _hoisted_1 = { class: "main" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    var _component_BaseHeader = _resolveComponent("BaseHeader");
    var _component_router_view = _resolveComponent("router-view");
    var _component_BaseFooter = _resolveComponent("BaseFooter");
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        _createVNode(_component_BaseHeader),
        _createElementVNode("main", _hoisted_1, [
            _createVNode(_component_router_view)
        ]),
        _createVNode(_component_BaseFooter)
    ], 64));
}
