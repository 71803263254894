import { __assign } from "tslib";
import { defineComponent } from 'vue';
import { productList } from '../data';
import { mapGetters } from 'vuex';
import HeadItem from '@/components/content/HeadItem.vue';
import { solutionList } from "@/views/solution/data";
export default defineComponent({
    name: 'FumaProduct',
    components: {
        HeadItem: HeadItem
    },
    data: function () {
        return {
            productList: productList,
            currentProductIndex: 0
        };
    },
    computed: __assign(__assign({}, mapGetters(['isMobile'])), { currentSolution: function () {
            return solutionList[this.id - 1];
        } }),
    methods: {
        goToUrl: function (info) {
            this.$router.push({
                path: info.to
            });
        }
    }
});
