import BaseHeader from './BaseHeader.vue';
// import BaseMain from './BaseMain.vue'
import BaseFooter from './BaseFooter.vue';
import { defineComponent } from 'vue';
export default defineComponent({
    name: 'Layout',
    components: {
        BaseHeader: BaseHeader,
        // BaseMain,
        BaseFooter: BaseFooter
    }
});
