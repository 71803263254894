import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "index" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_FumaSlider = _resolveComponent("FumaSlider")
  const _component_FumaSolution = _resolveComponent("FumaSolution")
  const _component_FumaProduct = _resolveComponent("FumaProduct")
  const _component_FumaMoment = _resolveComponent("FumaMoment")
  const _component_FumaAbout = _resolveComponent("FumaAbout")
  const _component_FumaContactUs = _resolveComponent("FumaContactUs")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_FumaSlider),
    _createVNode(_component_FumaSolution),
    _createVNode(_component_FumaProduct),
    _createVNode(_component_FumaMoment),
    _createVNode(_component_FumaAbout),
    _createVNode(_component_FumaContactUs)
  ]))
}