
export const solutionList = [
  {
    id: 1,
    name: '高速公路车路协同解决方案',
    diagramImg: require('@/assets/solution/gs-diagram.png'), // 原理图
    featureList: [
      {
        id: 100,
        name: '实现效果1',
        content: '效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字'
      },
      {
        id: 101,
        name: '实现效果2',
        content: '效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字'
      },
      {
        id: 102,
        name: '实现效果3',
        content: '效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字'
      },
      {
        id: 103,
        name: '实现效果4',
        content: '效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字'
      },
      {
        id: 104,
        name: '实现效果5',
        content: '效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字'
      },
      {
        id: 105,
        name: '实现效果6',
        content: '效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字'
      }
    ]
  },
  {
    id: 2,
    name: '高速公路车路协同解决方案2',
    diagramImg: require('@/assets/solution/gs-diagram.png'), // 原理图
    featureList: [
      {
        id: 100,
        name: '实现效果1',
        content: '效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字'
      },
      {
        id: 101,
        name: '实现效果2',
        content: '效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字'
      },
      {
        id: 102,
        name: '实现效果3',
        content: '效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字'
      },
      {
        id: 103,
        name: '实现效果4',
        content: '效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字'
      },
      {
        id: 104,
        name: '实现效果5',
        content: '效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字'
      },
      {
        id: 105,
        name: '实现效果6',
        content: '效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字'
      }
    ]
  },
  {
    id: 3,
    name: '高速公路车路协同解决方案3',
    diagramImg: require('@/assets/solution/gs-diagram.png'), // 原理图
    featureList: [
      {
        id: 100,
        name: '实现效果1',
        content: '效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字'
      },
      {
        id: 101,
        name: '实现效果2',
        content: '效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字'
      },
      {
        id: 102,
        name: '实现效果3',
        content: '效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字'
      },
      {
        id: 103,
        name: '实现效果4',
        content: '效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字'
      },
      {
        id: 104,
        name: '实现效果5',
        content: '效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字'
      },
      {
        id: 105,
        name: '实现效果6',
        content: '效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字'
      }
    ]
  },
  {
    id: 4,
    name: '高速公路车路协同解决方案4',
    diagramImg: require('@/assets/solution/gs-diagram.png'), // 原理图
    featureList: [
      {
        id: 100,
        name: '实现效果1',
        content: '效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字'
      },
      {
        id: 101,
        name: '实现效果2',
        content: '效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字'
      },
      {
        id: 102,
        name: '实现效果3',
        content: '效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字'
      },
      {
        id: 103,
        name: '实现效果4',
        content: '效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字'
      },
      {
        id: 104,
        name: '实现效果5',
        content: '效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字'
      },
      {
        id: 105,
        name: '实现效果6',
        content: '效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字效果展开描述文字'
      }
    ]
  }
]
