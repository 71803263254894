
import {defineComponent} from 'vue'
import FumaSlider from './components/FumaSlider.vue'
import FumaSolution from './components/FumaSolution.vue'
import FumaProduct from './components/FumaProduct.vue'
import FumaMoment from './components/FumaMoment.vue'
import FumaAbout from './components/FumaAbout.vue'
import FumaContactUs from './components/FumaContactUs.vue'
// import emailFn from '@/utils/qq'
import axios from 'axios'

export default defineComponent({
  name: 'Index',
  components: {
    FumaSlider,
    FumaSolution,
    FumaProduct,
    FumaMoment,
    FumaContactUs,
    FumaAbout,
  },
  watch: {
    '$store.state.homeElId': {
      handler(val) {
        console.log('哈哈哈，id改变了哦', val)
        if (val) {
          this.handleJump(val)
          this.$store.commit('SET_HOME_EL_ID', '')
        }
      },
      deep: true
    }
  },
  mounted() {
    // this.requestFn()
    // this.SendEail()
    console.log('首页加载', this.$store.state.homeElId)
    let id = this.$store.state.homeElId
    if (id) {
      this.handleJump(id)
    }
  },
  methods: {
    handleJump(id) {
      const dom = document.querySelector(`#${id}`)
      if (dom) {
        dom.scrollIntoView(true)
      }
    },
    SendEail() {
      // var data = decodeURIComponent(this.email.toString());
      // var decode_email = window.atob(data);
      console.log('ssssss')
      Email.send({
        Host: "smtp.163.com",  //邮箱地址
        Username: "lcg12252511@163.com",
        Password: 'KVCPXJNGKZPHNVPR',//授权码
        To: 'lcg12252511@163.com',//接收邮件的地址
        From: "1643391875@qq.com",//发送邮箱的地址
        Subject: "合规确认消息提醒",//邮件主题
        Body: "附件内容"  //邮件内容
      }).then(res => {
        console.log("发送成功", res) //发送成功提示
      }).catch(err => {
        console.log('发送失败', err)
      })
    },
    requestFn() {
      axios.post('https://formspree.io/f/mbjweqae', {
        "name": 'sdlfk',
        "姓名": 'lcg',
        "公司名称及职务": '您的公司名称及职务',
        "手机号码": '13699488562',
        "邮件地址": '251449673@qq.com',
        "需求": '您的需求',
      }).then(res => {
        console.log('请求成功了', res)
      }).catch(err => {
        console.log('请求失败了', err)
      })
    }
  }
})
