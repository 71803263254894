import { defineComponent } from 'vue';
import HeadItem from '@/components/content/HeadItem.vue';
export default defineComponent({
    name: 'FumaAbout',
    components: {
        HeadItem: HeadItem
    },
    data: function () {
        return {
            // aboutUsContent: '专注于无人驾驶行业，侧重于车路协同、无人驾驶车辆等解决方案的研发、制造、销售及服务的企业。',
            aboutUsContent: '福马智行专注于智能网联行业，侧重车路协同、无人驾驶车辆等智能网联产品的研发、制造、销售及服务。在成都、上海设有研发中心，拥有丰富的通信和自动驾驶研发经验。福马智行是四川省创新型中小企业、国家高新技术企业，已自研有“车-路-云”全系列智能网联产品，是国内少数同时拥有车路协同、智能网联车辆自研产品的设备厂商。',
            selfStyle: {
                'margin-top': '100px'
            }
        };
    },
    methods: {
        goToUrl: function (url) {
            this.$router.push({
                path: url
            });
        }
    }
});
