import { reactive, ref, Ref } from 'vue'
import config from '@/config/website'
import { WebsiteI } from '@/type'

interface WebConfigI {
  website: WebsiteI;
  indexUrl: Ref<string>;
}

export default function useWebsite ():WebConfigI {
  const website = reactive(config)
  const indexUrl = ref('')
  if (website.isOnline) {
    indexUrl.value = `http://${website.onlineUrl}`
  } else {
    indexUrl.value = `http://${website.localUrl}:${website.port}`
  }
  return {
    website,
    indexUrl
  }
}
