import { defineComponent, onMounted, onUnmounted, ref } from 'vue';
import HeadItem from '@/components/content/HeadItem.vue';
import { momentList } from '../data';
export default defineComponent({
    name: 'FumaMoment',
    components: {
        HeadItem: HeadItem
    },
    setup: function () {
        var timer;
        var list = ref(momentList);
        var currentMomentIndex = ref(0); // 当前动态的索引
        var hover = ref(false); // 鼠标是否在列表上
        var handleMouseEnter = function (index) {
            hover.value = true;
            // currentMomentIndex.value = index
        };
        var handleMouseleave = function () {
            hover.value = false;
        };
        onMounted(function () {
            // timer = setInterval(() => {
            //   if (!hover.value) {
            //     currentMomentIndex.value += 1
            //     if (currentMomentIndex.value > 5) {
            //       currentMomentIndex.value -= 6
            //     }
            //   }
            // }, 8000)
        });
        onUnmounted(function () {
            clearInterval(timer);
        });
        return {
            list: list,
            currentMomentIndex: currentMomentIndex,
            handleMouseEnter: handleMouseEnter,
            handleMouseleave: handleMouseleave
        };
    }
});
