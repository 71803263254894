import { __assign } from "tslib";
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
export default defineComponent({
    name: 'HeadItem',
    props: {
        title: {
            type: String,
            default: '默认标题'
        },
        desc: {
            type: String,
            default: '默认描述，默认描述。'
        },
        type: {
            type: String,
            default: 'l1'
        },
        showDesc: {
            type: Boolean,
            default: true
        },
        selfStyle: {
            type: Object
        }
    },
    computed: __assign({}, mapGetters(['isMobile']))
});
