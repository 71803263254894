import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function (n) { return (_pushScopeId("data-v-51065b86"), n = n(), _popScopeId(), n); };
var _hoisted_1 = ["disabled"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("button", {
        class: _normalizeClass(["btn base-button", [
                _ctx.type ? 'btn--' + _ctx.type : '',
                {
                    disabled: _ctx.disabled,
                    plane: _ctx.plane,
                    radius: _ctx.radius,
                }
            ]]),
        disabled: _ctx.disabled
    }, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 10, _hoisted_1));
}
