import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function (n) { return (_pushScopeId("data-v-95a0cf72"), n = n(), _popScopeId(), n); };
var _hoisted_1 = { class: "submenu-card" };
var _hoisted_2 = { class: "title" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("h2", null, _toDisplayString(_ctx.title), 1),
            _renderSlot(_ctx.$slots, "title-suffix", {}, undefined, true)
        ])
    ]));
}
