import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_ctx.scrollY.currentScrollY > 600)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "back-top",
            onClick: _cache[0] || (_cache[0] =
                //@ts-ignore
                function () {
                    var args = [];
                    for (var _i = 0; _i < arguments.length; _i++) {
                        args[_i] = arguments[_i];
                    }
                    return (_ctx.handleClick && _ctx.handleClick.apply(_ctx, args));
                })
        }))
        : _createCommentVNode("", true);
}
