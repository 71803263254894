import { defineComponent } from 'vue';
export default defineComponent({
    name: 'SubMenuCard',
    props: {
        title: String,
        content: String
    },
    data: function () {
        return {
        // msg: 'hello vue3'
        };
    }
});
