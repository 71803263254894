import { defineComponent } from 'vue';
export default defineComponent({
    name: 'BaseButton',
    props: {
        type: {
            type: String,
            default: 'default'
        },
        disabled: {
            type: Boolean,
            default: false
        },
        plane: {
            type: Boolean,
            default: false
        },
        radius: {
            type: Boolean,
            default: false
        }
    }
});
