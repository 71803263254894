import {createStore} from 'vuex'
import {ScreenSize} from '@/type'

export interface ScrollYI {
  isDown: boolean;
  currentScrollY: number;
}

export default createStore({
  state: {
    screenSize: '' as ScreenSize,
    scrollY: {
      isDown: true,
      currentScrollY: 0
    } as ScrollYI,
    homeElId: ''
  },
  mutations: {
    SET_SCREEN_SIZE(state, size: ScreenSize) {
      state.screenSize = size
    },
    SET_SCROLL_Y(state, scrollY: number) {
      state.scrollY.isDown = state.scrollY.currentScrollY < scrollY
      state.scrollY.currentScrollY = scrollY
    },
    SET_HOME_EL_ID(state, el: string) {
      state.homeElId = el
    }
  },
  actions: {},
  modules: {},
  getters: {
    isMobile(state) {
      return state.screenSize === 'small'
    }
  }
})
