import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function (n) { return (_pushScopeId("data-v-ab83d386"), n = n(), _popScopeId(), n); };
var _hoisted_1 = { class: "black-9" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", {
        style: _normalizeStyle(_ctx.selfStyle),
        class: _normalizeClass(["head-item", [{ small: _ctx.isMobile }, _ctx.type]])
    }, [
        _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.title), 1)
    ], 6));
}
