
import { WebsiteI } from '../type'

export default {
  companyName: '成都福马智行科技有限公司',
  companyAddress: '成都高新区天府四街300号财智中心3栋B座4层02号',
  onlineUrl: '192.168.0.58',
  localUrl: '192.168.0.58',
  port: 8866,
  isOnline: false
} as WebsiteI
