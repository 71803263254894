export interface DropMenuI {
  id: number;
  pid: number;
  title: string;
  titleShort: string; // 短标题
  desc: string;
  abstract: string;
  suffix?: string;
  to?: string;
  specialDeal?: boolean,

  [propsName: string]: any,
}

export interface NavItemI {
  id: number;
  name: string;
  newPage: boolean;
  to?: string;
  dropMenu?: DropMenuI[];

  [propsName: string]: any
}

export const navList: NavItemI[] = [
  {
    id: 1,
    name: '首页',
    newPage: true,
    to: '/'
  },
  {
    id: 2,
    name: '解决方案',
    newPage: false,
    to: 'solution',
    dropMenu: [{
      id: 1,
      pid: 2,
      title: '车路协同解决方案',
      titleShort: '车路协同',
      desc: '高速公路车路协同解决方案是福马智行自研的.....',
      abstract: '',
      to: 'solution/1',
      specialDeal: true
    }
    /*, {
      id: 2,
      pid: 2,
      title: '全天候通行方案',
      titleShort: '全天候',
      desc: '全天候通行方案是福马智行自研的.....',
      abstract: '一段简介一段简介一段简介'
    }, {
      id: 3,
      pid: 2,
      title: '园区清洁方案',
      titleShort: '园区清洁',
      desc: '园区清洁方案是福马智行自研的.....',
      abstract: '一段简介一段简介一段简介'
    },
      {
      id: 4,
      pid: 2,
      title: '园区清洁方案',
      titleShort: '园区清洁',
      desc: '园区清洁方案是福马智行自研的.....',
      abstract: '一段简介一段简介一段简介'
    }*/
    ]
  },
  {
    id: 3,
    name: '产品介绍',
    newPage: false,
    to: 'productDetail',
    dropMenu: [
      {
        id: 6,
        pid: 3,
        to: 'ProductRSCU',
        title: '边缘计算单元RSCU',
        titleShort: 'RSCU',
        desc: '部署于路侧的计算单元，专用于收集和处理交通感知数据和车辆数据',
        abstract: '部署于路侧的计算单元，专用于收集和处理交通感知数据和车辆数据',
        specialDeal: true
      },
      {
        id: 4,
        pid: 3,
        to: 'ProductRSU',
        title: '路侧单元RSU',
        titleShort: 'RSU',
        desc: '可为封闭园区、厂区及景区等场景提供接驳、观光服务',
        abstract: '可为封闭园区、厂区及景区等场景提供接驳、观光服务',
        specialDeal: true
      },
      {
        id: 3,
        pid: 3,
        to: 'ProductOBU',
        title: '车载单元OBU',
        titleShort: 'OBU',
        desc: '适用于车端的V2X通信设备',
        abstract: '适用于车端的V2X通信设备',
        specialDeal: true
      },
      {
        id: 5,
        pid: 3,
        to: 'ProductLSYTJ',
        title: '雷视一体机',
        titleShort: '雷视一体机',
        desc: '毫米波雷达和视觉相机两种感知技术融合一体化设',
        abstract: '毫米波雷达和视觉相机两种感知技术融合一体化设',
        specialDeal: true
      },
      {
        id: 8,
        pid: 3,
        to: 'ProductCouldCPlatform',
        title: '车路协同云控平台',
        titleShort: '云控平台',
        desc: '汇总车路协同路侧设备及智能网联车辆的各种信息，进行分析和可视化展示',
        abstract: '汇总车路协同路侧设备及智能网联车辆的各种信息，进行分析和可视化展示',
        specialDeal: true
      },
      {
        id: 7,
        pid: 3,
        to: 'ProductHMI',
        title: '车载HMI软件',
        titleShort: 'HMI',
        desc: '配合车载单元OBU实现OBU收发数据可视化展示',
        abstract: '配合车载单元OBU实现OBU收发数据可视化展示',
        specialDeal: true
      },
      {
        id: 1,
        pid: 3,
        to: 'MoveDevice',
        title: '移动式车路协同',
        titleShort: '观光车',
        desc: '可为封闭园区、厂区及景区等场景提供接驳、观光服务',
        abstract: '可为封闭园区、厂区及景区等场景提供接驳、观光服务',
        specialDeal: true
      }, {
        id: 2,
        pid: 3,
        to: 'ProductTrainBus',
        title: '无人驾驶实训车',
        titleShort: '实训车',
        desc: '适用于实训、教学、科研、竞赛等多种场景任务。',
        abstract: '适用于实训、教学、科研、竞赛等多种场景任务。',
        specialDeal: true
      },
       {
       id: 9,
       pid: 3,
       to: 'ProductByWireChassis',
       title: '线控底盘',
       titleShort: '线控底盘',
       desc: '一套对车辆、路侧设备相关数据进行收集和分析处理的车路协同管理系统',
       abstract: '一套对车辆、路侧设备相关数据进行收集和分析处理的车路协同管理系统',
       specialDeal: true
     },
       {
       id: 10,
       pid: 3,
       to: 'ProductVCU',
       title: '整车控制器VCU',
       titleShort: 'VCU',
       desc: '一套对车辆、路侧设备相关数据进行收集和分析处理的车路协同管理系统',
       abstract: '一套对车辆、路侧设备相关数据进行收集和分析处理的车路协同管理系统',
       specialDeal: true
     }
    ]
  },
  {
    id: 4,
    name: '公司动态',
    newPage: false,
    to: 'moment',
    homePageTarget: true,
    special: true
  },
  {
    id: 5,
    name: '关于福马智行',
    newPage: false,
    to: 'aboutUs', homePageTarget: true,
    special: true,

  },
  /* {
     id: 5,
     name: '关于福马',
     newPage: false,
     to: 'about',
     dropMenu: [{
       id: 1,
       pid: 5,
       title: '公司介绍',
       titleShort: '公司介绍',
       desc: '成都福马智行科技有限公司',
       abstract: '成都福马智行科技有限公司',
       to: 'aboutUs',
       homePageTarget: true,
       specialDeal: true   // 单独处理
     }, {
       id: 2,
       pid: 5,
       title: '招贤纳士',
       titleShort: '招贤纳士',
       desc: '成都福马智行科技有限公司',
       abstract: '成都福马智行科技有限公司',
       suffix: '🔥',
       to: 'jobList',
       specialDeal: true   // 单独处理
     }]
   }*/
]
