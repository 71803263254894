import { __assign } from "tslib";
import { defineComponent } from 'vue';
import SubMenuCard from '@/components/common/SubMenuCard.vue';
import { mapState, mapGetters } from 'vuex';
import { navList } from './data';
export default defineComponent({
    name: 'BaseHeader',
    components: {
        SubMenuCard: SubMenuCard
    },
    data: function () {
        return {
            navList: navList,
            showMobileDropMenu: false,
            dropMenuList: [],
            showSubmenu: false,
            setDropMenuListTimer: null,
            currentMenuId: 0,
            mSubMenuActiveId: -1
        };
    },
    methods: {
        closeMenu: function () {
            this.showMobileDropMenu = false;
            this.mSubMenuActiveId = -1;
            document.body.style.height = 'auto';
            document.body.style.overflow = 'auto';
        },
        openMenu: function () {
            document.body.style.height = '100%';
            document.body.style.overflow = 'hidden';
            this.showMobileDropMenu = true;
        },
        // 打开移动端菜单
        openDropMenuMobile: function (id) {
            if (this.mSubMenuActiveId === id) {
                this.mSubMenuActiveId = -1; // 关闭menu
            }
            else {
                this.mSubMenuActiveId = id;
            }
        },
        // 鼠标移到导航栏
        handleNavMouseEnter: function (item) {
            if (!this.isMobile) {
                this.currentMenuId = item.id;
            }
        },
        // 点击导航栏
        handleClickNav: function (item) {
            if (!item.dropMenu) {
                this.showMobileDropMenu = false;
                document.body.style.height = 'auto';
                document.body.style.overflow = 'auto';
            }
            console.log('点击事件', item);
            if (item.newPage) {
                this.$router.push({ path: item.to });
                // 关闭菜单
                this.closeMenu();
            }
            if (item.homePageTarget) {
                this.$store.commit('SET_HOME_EL_ID', item.to);
                this.$router.push({ path: '/' });
            }
            this.currentMenuId = null;
        },
        handleClickNavItem: function (_a) {
            var path = _a.path, props = _a.props, subItem = _a.subItem;
            if (subItem.specialDeal) { // 特殊处理 关于福马 模块
                if (subItem.homePageTarget) { // 需要跳转到首页
                    this.$store.commit('SET_HOME_EL_ID', subItem.to);
                    this.$router.push({ path: '/' });
                }
                else {
                    this.$router.push({ path: "/" + subItem.to });
                }
            }
            else {
                this.$router.push({ path: "/" + path + "/" + props });
            }
        },
        // 鼠标移出导航栏
        handleMouseLeaveSubmenu: function () {
            this.showSubmenu = false;
            this.dropMenuList = [];
        },
        handleIndexClick: function () {
            this.$router.push('/');
        },
        // 手机端上面二级路由的点击事件
        mobileNavHandler: function (info) {
            this.$router.push({
                path: info.to
            });
            this.showMobileDropMenu = false;
            document.body.style.height = 'auto';
            document.body.style.overflow = 'auto';
            console.log('info', info.to);
        }
    },
    computed: __assign(__assign({}, mapState(['scrollY'])), mapGetters(['isMobile']))
});
