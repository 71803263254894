import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function (n) { return (_pushScopeId("data-v-8433c33a"), n = n(), _popScopeId(), n); };
var _hoisted_1 = { class: "base-card" };
var _hoisted_2 = { class: "subtitle" };
var _hoisted_3 = { class: "content" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("h3", null, _toDisplayString(_ctx.title), 1),
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.subTitle), 1),
        _createElementVNode("section", _hoisted_3, _toDisplayString(_ctx.content), 1),
        _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
    ]));
}
