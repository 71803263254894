// eslint-disable-next-line @typescript-eslint/ban-types
export const debounce = (fn:Function, delay = 500, immediate = false):Function | void => {
  let timer = 0
  let first = immediate
  return function (...args:Array<unknown>):void {
    clearTimeout(timer)
    if (immediate && first) {
      first = false
      fn.apply(this, args)
    } else {
      // clearTimeout(timer)
      timer = setTimeout(() => {
        fn.apply(this, args)
        first = true
      }, delay)
    }
  }
}
