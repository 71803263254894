import { defineComponent } from 'vue';
import useWebsite from '@/composable/useWebsite';
import BackTop from '@/components/common/BackTop.vue';
export default defineComponent({
    name: 'BaseFooter',
    setup: function () {
        var _a = useWebsite(), website = _a.website, indexUrl = _a.indexUrl;
        return {
            website: website,
            indexUrl: indexUrl
        };
    },
    components: {
        BackTop: BackTop
    }
});
