import {createRouter, createWebHashHistory, RouteRecordRaw} from 'vue-router'
import Layout from '../layout/Layout.vue'
import Index from '@/views/index/Index.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Layout',
    component: Layout,
    children: [{
      path: '',
      component: Index
    }, {
      path: 'solution/:id',
      component: () => import(/* webpackChunkName: "solution" */'@/views/solution/Solution.vue'),
      props: true
      // children:
    }, {
      path: 'moment',
      component: () => import(/* webpackChunkName: "moment" */'@/views/moment/Moment.vue')
    }, {
      path: 'product/:id',
      component: () => import(/* webpackChunkName: "product" */'@/views/product/Product.vue'),
    }, {
      path: 'productDetail/:id',
      component: () => import(/* webpackChunkName: "product" */'@/views/product/ProductDetail.vue'),
    },
      {
        path: '/MoveDevice',
        component: () => import(/* webpackChunkName: "other" */'@/views/product/MoveDevice.vue')
      }, {
        path: '/ProductTrainBus',
        component: () => import(/* webpackChunkName: "other" */'@/views/product/ProductTrainBus.vue')
      }, {
        path: '/ProductOBU',
        component: () => import(/* webpackChunkName: "other" */'@/views/product/ProductOBU.vue')
      }, {
        path: '/ProductRSU',
        component: () => import(/* webpackChunkName: "other" */'@/views/product/ProductRSU.vue')
      }, {
        path: '/ProductLSYTJ',
        component: () => import(/* webpackChunkName: "other" */'@/views/product/ProductLSYTJ.vue')
      }, {
        path: '/ProductRSCU',
        component: () => import(/* webpackChunkName: "other" */'@/views/product/ProductRSCU.vue')
      }, {
        path: '/ProductHMI',
        component: () => import(/* webpackChunkName: "other" */'@/views/product/ProductHMI.vue')
      }, {
        path: '/ProductCouldCPlatform',
        component: () => import(/* webpackChunkName: "other" */'@/views/product/ProductCouldCPlatform.vue')
      },{
        path: '/ProductByWireChassis',
        component: () => import(/* webpackChunkName: "other" */'@/views/product/ProductByWireChassis.vue')
      },{
        path: '/ProductVCU',
        component: () => import(/* webpackChunkName: "other" */'@/views/product/ProductVCU.vue')
      }, {
        path: '/ProductManSystem',
        component: () => import(/* webpackChunkName: "other" */'@/views/product/ProductManSystem.vue')
      }
      , {
        path: '/jobList',
        component: () => import(/* webpackChunkName: "jobList" */'@/views/aboutUs/JobList.vue')
      },
      {
        path: 'about',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '@/views/index/About.vue')
      }]
  }, {
    path: '/deal',
    component: () => import(/* webpackChunkName: "other" */'@/views/other/Deal.vue')
  }, {
    path: '/privacy',
    component: () => import(/* webpackChunkName: "other" */'@/views/other/Privacy.vue')
  }
]

const router = createRouter({
  // history: createWebHistory(),
  history: createWebHashHistory(),
  routes
})

// 处理页面的跳转问题
router.afterEach((to, from, next) => {
  if(to.fullPath.includes('Product')||to.fullPath.includes('solution')){
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  }
})

export default router
