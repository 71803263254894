import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import 'element-plus/dist/index.css'

import axios from 'axios'

// 导入样式
import '@/style/style.scss'
// 字体图标
import '@/assets/lib/iconfont.css'
// 全局组件
import BaseButton from '@/components/common/BaseButton.vue'

const app = createApp(App)

app.component('BaseButton', BaseButton)

app.use(store)
  .use(router)
  .mount('#app')

