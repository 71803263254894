import { __assign } from "tslib";
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
export default defineComponent({
    name: 'FumaSlider',
    components: {},
    data: function () {
        return {
            swiper: null
        };
    },
    methods: {
        init: function () {
            var swiper = new window.Swiper('#fm-swiper', {
                direction: 'horizontal',
                speed: 400,
                loop: false,
                pagination: {
                    el: '.swiper-pagination',
                    type: 'bullets',
                    clickable: false
                },
                autoplay: {
                    delay: 5000
                },
                centeredSlides: true,
                effect: 'slide'
            });
            this.swiper = swiper;
        }
    },
    mounted: function () {
        // this.init()
    },
    computed: __assign({}, mapGetters(['isMobile'])),
    watch: {
        isMobile: function () {
            // window.location.reload()
        }
    }
});
