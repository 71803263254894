import { computed, defineComponent } from 'vue';
import { useStore } from 'vuex';
export default defineComponent({
    name: 'BackTop',
    setup: function () {
        var handleClick = function () {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        };
        var store = useStore();
        var scrollY = computed(function () {
            return store.state.scrollY;
        });
        return {
            handleClick: handleClick,
            scrollY: scrollY
        };
    }
});
