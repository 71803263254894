import { defineComponent } from 'vue';
import { debounce } from '@/utils/util';
export default defineComponent({
    name: 'App',
    methods: {
        // 获取屏幕尺寸
        initScreenSize: function () {
            var screenSize;
            var size = document.body.clientWidth;
            if (size < 768) {
                screenSize = 'small';
            }
            else if (size >= 768 && size < 1440) {
                screenSize = 'medium';
            }
            else {
                screenSize = 'large';
            }
            this.$store.commit('SET_SCREEN_SIZE', screenSize);
        },
        // 获取屏幕向下滚动的距离
        initScrollY: function () {
            // const scrollY = window.scrollY
            this.$store.commit('SET_SCROLL_Y', window.scrollY);
        }
    },
    created: function () {
        var _this = this;
        /**
         * 监听屏幕尺寸变化
         */
        this.initScreenSize();
        this.debouncedInitScreenSize = debounce(function () {
            _this.initScreenSize();
        }, 100, true);
        addEventListener('resize', function () {
            _this.debouncedInitScreenSize();
        });
        /**
         * 监听鼠标下滑
         */
        this.initScrollY();
        this.debouncedInitScrollY = debounce(function () {
            _this.initScrollY();
        }, 100, true);
        // this.debouncedInitScreenSize =
        addEventListener('scroll', function () {
            _this.debouncedInitScrollY();
        });
    }
});
