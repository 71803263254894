
import axios from 'axios'
import { ElMessage } from 'element-plus'

export default {
  name: "FumaContactUs",
  data() {
    return {
      submitLoading: false,
      id: 'bpidpumpbczlecie',
      label: '联系我们',
      englishLabel: 'CONTACT US',
      desc: '加盟合作类文字加盟合作类文字加盟合作类文字加盟合作类文字加盟合作类文字',
      formData: {
        username: null,
        companyInfo: null,
        tel: null,
        email: null,
        detail: null
      }
    }
  },
  methods: {
    submitHandler() {
      const {username, companyInfo, tel, email, detail} = this.formData
      const emailRegExp = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
      const telRegExp = /^[1][0-9][0-9]{9}$/
      if (!username){
        ElMessage({
          type:'warning',
          message: '请输入您的姓名'
        })
        return
      }
      if (!companyInfo){
        ElMessage({
          type:'warning',
          message: '请输入您的公司名称及职务'
        })
        return
      }
      if (!telRegExp.test(tel)){
        ElMessage({
          type:'warning',
          message: '您的手机号码格式不对'
        })
        return
      }
      if (!emailRegExp.test(email)){
        ElMessage({
          type:'warning',
          message: '您的邮箱格式不对'
        })
        return
      }
      if (!detail){
        ElMessage({
          type:'warning',
          message: '请输入您的需求'
        })
        return
      }
      if (username && companyInfo && telRegExp.test(tel) && emailRegExp.test(email) && detail) {
        this.submitLoading = true
        axios.post('https://formspree.io/f/xoqrzrpq', {
          "姓名": username,
          "公司名称及职务": companyInfo,
          "手机号码": tel,
          "邮件地址": email,
          "需求": detail,
        }).then(res => {
          this.submitLoading = false
          ElMessage({
            type:'success',
            message: '提交成功'
          })
          console.log('请求成功了', res.data.ok)
        }).catch(err => {
          console.log('请求失败了', err)
          this.submitLoading = false
        })
      }

      console.log(username, companyInfo, tel, email, detail)
    },
  }
}
