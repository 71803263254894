import { defineComponent } from 'vue';
export default defineComponent({
    name: 'BaseCard',
    props: {
        title: {
            type: String,
            default: '标题'
        },
        subTitle: String,
        content: String
    }
});
