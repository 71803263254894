import { __assign } from "tslib";
import { solutionList } from '../data';
import { defineComponent } from 'vue';
import { mapGetters, mapState } from 'vuex';
import BaseCard from '@/components/common/BaseCard.vue';
import BaseButton from '@/components/common/BaseButton.vue';
import HeadItem from '@/components/content/HeadItem.vue';
export default defineComponent({
    name: 'FumaSolution',
    components: {
        BaseCard: BaseCard,
        BaseButton: BaseButton,
        HeadItem: HeadItem
    },
    data: function () {
        return {
            solutionList: solutionList,
            solutionInfo: {
                title: '车路协同解决方案',
                scene: '城市道路、高速公路、教学科研',
                detail: '车路协同解决方案，就是“聪明的路”解决方案，提高了车和路的“智能化水平”，通过部署车路协同路侧单元RSU、雷视一体机以及云控平台，实现道路超视距感知、盲点感知，通过部署边缘计算单元RSCU，实时对道路交通状况进行分析，及时给往来车辆发告警，从而提高了车辆行驶的安全性，避免拥堵，大大提升了通行效率。 '
            }
        };
    },
    computed: __assign(__assign({}, mapGetters(['isMobile'])), mapState(['screenSize']))
});
